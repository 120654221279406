.team-section {
    padding: 80px 0;
    /* background: #f8f9fa; */
  }
  
  .team-card {
    border: none;
    /* background: white; */
    transition: transform 0.3s ease-in-out;
  }
  
  .team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .team-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 20px auto;
    display: block;
  }
  