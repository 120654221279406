.hero-section {
    position: relative;
    padding: 80px 0;
    background: #f8f9fa;
  }
  
  .hero-section h1 {
    color: #212529;
  }
  
  .hero-wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  #about-us,#services {
    scroll-margin-top: 60px; /* adjust this value to match your navbar height */
  }