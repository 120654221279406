.pyramid-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .pyramid-section {
    display: flex;
    align-items: center;
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pyramid-number {
    font-size: 18px;
    font-weight: bold;
    background: #007bff;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .pyramid-content {
    flex: 1;
  }
  