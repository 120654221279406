.services-section {
    padding: 60px 0;
    background: #fff;
  }
  
  .service-img {
    max-height: 200px;
    width: 70%; 
    margin: 0 auto; 
    display: block; 
}