.grc-section {
    padding: 80px 0;
    background: #fff;
  }
  
  .grc-img {
    max-width: 100%;
    height: auto;
  }
  
  .grc-service {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .grc-service .icon {
    color: #007bff;
    font-size: 24px;
  }
  